import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { MenuItem, Select, Tab, Tabs } from '@mui/material';

import AddPiece from './piece-new-button';
import { setPieceFilter } from '../services/actions';
import { imgNamesToDetails } from '../services/helpers';

const linkField = (params) => (
  <Link to={`/items/${params.row.id}`}>{params.value}</Link>
);

// See https://mui.com/x/react-data-grid/columns/ for more column options
const cols = [
  {
    field: 'images',
    headerName: 'Thumbnail',
    width: 140,
    renderCell: (params) => {
      const details = imgNamesToDetails(params.row.images);
      if (details.length) {
        return (
          <Link to={`/items/${params.row.id}`}>
            <img
              className="piece-thumbnail"
              src={details[0].src}
              alt={details[0].fileName}
            />
          </Link>
        );
      } else {
        return <Link to={`/items/${params.row.id}`}>No thumbnail</Link>;
      }
    },
  },
  {
    field: 'notes',
    headerName: 'Notes',
    width: 160,
    renderCell: linkField,
  },
  {
    field: 'type',
    headerName: 'Type',
  },
  {
    field: 'clay_type',
    headerName: 'Clay',
  },
  {
    field: 'date_thrown',
    headerName: 'Thrown',
  },
  {
    field: 'date_to_bisque',
    headerName: 'To Bisque',
  },
  {
    field: 'date_to_glaze',
    headerName: 'To Glaze',
  },
  {
    field: 'glaze',
    headerName: 'Glaze notes',
    width: 180,
  },
  {
    field: 'clay_weight',
    headerName: 'Weight',
  },
  {
    field: 'date_trimmed',
    headerName: 'Trimmed',
  },
  {
    field: 'returned_from_bisque',
    headerName: 'Back from Bisque',
  },
  {
    field: 'returned_from_glaze',
    headerName: 'Back from Glaze',
  },
  {
    field: 'id',
    headerName: 'ID',
    renderCell: linkField,
  },
];

const PiecesList = ({ pieces, filteredView, setPieceFilter }) => {
  const [sortModel, setSortModel] = useState([
    { field: 'date_thrown', sort: 'asc' },
  ]);
  useEffect(() => {
    if (filteredView === 'atBisque') {
      setSortModel([
        {
          field: 'date_to_bisque',
          sort: 'asc',
        },
      ]);
    } else if (filteredView === 'atGlaze') {
      setSortModel([
        {
          field: 'date_to_glaze',
          sort: 'asc',
        },
      ]);
    }
  }, [filteredView]);

  const toggleFilter = (e, filterType) => {
    if (filterType === filteredView) setPieceFilter('');
    else setPieceFilter(filterType);
  };

  const filteredPieces = pieces.filter((piece) => {
    if (filteredView === 'toTrim') {
      return (
        !piece.date_trimmed &&
        !piece.is_hand_built &&
        !(
          piece.date_to_bisque ||
          piece.returned_from_bisque ||
          piece.date_to_glaze ||
          piece.returned_from_glaze ||
          piece.ruined
        )
      );
    }
    if (filteredView === 'trimmed') {
      return (
        (piece.date_trimmed || piece.is_hand_built) &&
        !(
          piece.date_to_bisque ||
          piece.returned_from_bisque ||
          piece.date_to_glaze ||
          piece.returned_from_glaze ||
          piece.ruined
        )
      );
    }
    if (filteredView === 'atBisque') {
      return (
        piece.date_to_bisque &&
        !(
          piece.returned_from_bisque ||
          piece.date_to_glaze ||
          piece.returned_from_glaze ||
          piece.ruined
        )
      );
    }
    if (filteredView === 'needsGlaze') {
      return (
        piece.returned_from_bisque &&
        !(piece.date_to_glaze || piece.returned_from_glaze || piece.ruined)
      );
    }
    if (filteredView === 'atGlaze') {
      return (
        piece.date_to_glaze && !(piece.returned_from_glaze || piece.ruined)
      );
    }
    if (filteredView === 'inProgress') {
      return !(piece.returned_from_glaze || piece.ruined);
    }
    if (filteredView === 'complete') {
      return piece.returned_from_glaze && !piece.ruined;
    }
    if (filteredView === 'ruined') {
      return piece.ruined;
    }
    return true; // default, return all
  });

  return (
    <div>
      <div style={{ paddingBottom: 20 }}>
        <div className="onSmallScreens">
          <Select
            value={filteredView}
            displayEmpty
            onChange={(e) => setPieceFilter(e.target.value)}
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="toTrim">Needs Trimming</MenuItem>
            <MenuItem value="trimmed">Drying Out</MenuItem>
            <MenuItem value="atBisque">At Bisque</MenuItem>
            <MenuItem value="needsGlaze">Needs Glazing</MenuItem>
            <MenuItem value="atGlaze">At Glaze</MenuItem>
            <MenuItem value="inProgress">In Progress</MenuItem>
            <MenuItem value="complete">Complete</MenuItem>
            <MenuItem value="ruined">Ruined</MenuItem>
          </Select>
        </div>
        <div className="onLargeScreens">
          <Tabs
            value={filteredView}
            onChange={toggleFilter}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab value="" label="All" />
            <Tab value="toTrim" label="Needs Trimming" />
            <Tab value="trimmed" label="Drying Out" />
            <Tab value="atBisque" label="At Bisque" />
            <Tab value="needsGlaze" label="Needs Glazing" />
            <Tab value="atGlaze" label="At Glaze" />
            <Tab value="inProgress" label="In Progress" />
            <Tab value="complete" label="Complete" />
            <Tab value="ruined" label="Ruined" />
          </Tabs>
        </div>
        <div style={{ minHeight: 160, height: '70vh' }}>
          <DataGrid
            rows={filteredPieces}
            columns={cols}
            rowHeight={85}
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
          />
        </div>
      </div>
      <AddPiece />
    </div>
  );
};
const mstp = (s) => ({
  pieces: s.pieces.pieces,
  filteredView: s.pieces.listFilter,
});
const mdtp = (d) => ({
  setPieceFilter: setPieceFilter(d),
});
export default connect(mstp, mdtp)(PiecesList);
