import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Button from '@mui/material/Button';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { imgNamesToDetails } from '../../services/helpers';
import PotteryImage from '../image-pottery';

// https://contactmentor.com/react-drag-drop-list/

const DNDImages = ({ images, onListChange }) => {
  const fullImages = imgNamesToDetails(images);

  const handleDrop = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;

    let updatedList = [...images];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);

    // trigger update
    onListChange(updatedList);
  };

  const setAsFirst = (listIndex) => (event) => {
    let copy = [...images];
    const [toFront] = copy.splice(listIndex, 1);
    onListChange([toFront, ...copy]);
  };

  const onRemoveClick = (listIndex) => (event) => {
    let copy = [...images];
    copy.splice(listIndex, 1);
    onListChange(copy);
  };

  return (
    <div>
      <DragDropContext onDragEnd={handleDrop}>
        <Droppable droppableId="list-container">
          {(provided) => (
            <div
              className="list-container"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {fullImages.map((img, index) => (
                <Draggable
                  key={img.fileName}
                  draggableId={img.fileName}
                  index={index}
                >
                  {(provided) => (
                    <div
                      className="imageDnd"
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                    >
                      <PotteryImage image={img} />
                      <br />
                      <Button variant="contained" onClick={setAsFirst(index)}>
                        Set as Primary
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={onRemoveClick(index)}
                      >
                        <DeleteForeverIcon />
                      </Button>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};
export default DNDImages;
