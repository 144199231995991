import React, { useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import PotteryImage from '../image-pottery';
import ImageAdd from '../image-add';

const PieceImageAdd = ({ pieceId, allImages, selectedImages, onSelect }) => {
  const [showHiddenIfFull, setshowHiddenIfFull] = useState(false);
  const toggleshowHiddenIfFull = (event) =>
    setshowHiddenIfFull(event.target.checked);

  const images = allImages
    .filter((img) => !img.hidden) // filter out hidden photos
    .filter((img) => !img.isFullAssoc || showHiddenIfFull) // filter out full photos if showHiddenIfFull is false
    .filter((img) => !selectedImages.includes(img.fileName))
    .map((img) => (
      <PotteryImage
        image={img}
        key={img.fileName}
        onClick={() => onSelect(img.fileName)}
      />
    ));

  return (
    <div>
      <ImageAdd pieceId={pieceId} />
      <div>
        <FormControlLabel
          control={
            <Switch
              checked={showHiddenIfFull}
              onChange={toggleshowHiddenIfFull}
              name="hiddenFull"
            />
          }
          label="Show images linked to other pieces"
        />
      </div>
      {images}
    </div>
  );
};
export default PieceImageAdd;
