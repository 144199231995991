import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getImageUrl } from '../services/actions';

const PotteryImage = ({ image, onClick }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (!image.src) getImageUrl(dispatch)(image.fileName);
  }, [image.src]);

  return (
    <div className="pottery-image" onClick={onClick}>
      <img src={image.src} alt={image.fileName} />
      <div>{image.fileName}</div>
    </div>
  );
};
export default PotteryImage;
