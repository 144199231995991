import React from 'react';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import DataViewer from './tool-data-viewer';
import {
  checkImageDirectory,
  loadData,
  saveStoreToDbx,
  setImagesFull,
} from '../services/actions';


const Tools = ({ checkImageDirectory, loadData, saveStoreToDbx, pieces, setImagesFull }) => {
  const onLoad = e => loadData();
  const onCheck = e => checkImageDirectory();
  const onSetFull = e => setImagesFull(pieces);

  return (
    <div>
      <h1>Tools</h1>
      <div>
        <Button variant="outlined" onClick={saveStoreToDbx}>Save to Dropbox</Button>
      </div>
      <div>
        <Button variant="outlined" onClick={onLoad}>Load Data from Dropbox</Button>
      </div>
      <div>
        <Button variant="outlined" onClick={onCheck}>Check for images</Button>
      </div>
      <div>
        <Button variant="outlined" onClick={onSetFull}>Set Image Full Property</Button>
      </div>
      <span>TBD data validation</span>

      <DataViewer />
    </div>
  );
};

const mstp = s => ({
  pieces: s.pieces.pieces,
});
const mdtp = d => ({
  loadData: loadData(d),
  saveStoreToDbx: saveStoreToDbx(d),
  checkImageDirectory: checkImageDirectory(d),
  setImagesFull: setImagesFull(d),
});
export default connect(mstp, mdtp)(Tools);
