import findIndex from 'lodash/findIndex';
import remove from 'lodash/remove';
import * as actions from './action-types';
import { deepCopy } from './helpers';

const initialState = {
  pieces: [],
  listFilter: 'inProgress',
  doingWork: false,
  error: null,
};

function addPiece(pieces, newPiece) {
  return [...pieces, newPiece];
}

function updatePieces(pieces, pieceId, updatedPiece) {
  const next = [...pieces];
  const index = findIndex(next, { id: pieceId });
  if (index < 0) {
    console.log(`Could not find piece to update with id ${pieceId}`);
    return pieces;
  }
  next[index] = updatedPiece;
  return next;
}

function removePiece(pieces, pieceId) {
  const next = [...pieces];
  remove(next, { id: pieceId });
  return next;
}

function addImage(pieces, pieceId, fileName) {
  const index = findIndex(pieces, { id: pieceId });
  if (index < 0) {
    console.log(`Could not find piece to add image with id ${pieceId}`);
    return pieces;
  }
  const piece = deepCopy(pieces[index]);
  piece.images = piece.images || [];
  piece.images.push(fileName);
  return updatePieces(pieces, pieceId, piece);
}

export function piecesReducer(state = initialState, action = { type: null }) {
  switch (action.type) {
    case actions.PIECE_ADD:
      return {
        ...state,
        pieces: addPiece(state.pieces, action.piece),
      };
    case actions.PIECE_UPDATE:
      return {
        ...state,
        pieces: updatePieces(state.pieces, action.id, action.piece),
      };
    case actions.PIECE_REMOVE:
      return {
        ...state,
        pieces: removePiece(state.pieces, action.pieceId),
      };
    case actions.PIECE_ADD_IMAGE:
      return {
        ...state,
        pieces: addImage(state.pieces, action.id, action.fileName),
      };
    case actions.PIECE_LIST_SET_FILTER:
      return {
        ...state,
        listFilter: action.filter,
      };
    case actions.SYNC_FROM_DBX_SUCCESS:
      return {
        ...state,
        pieces: action.pieces,
      };
    case actions.LOGOUT:
      return initialState;
    default:
      return state;
  }
}
