import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import moment from 'moment';

import Button from '@mui/material/Button';
import { addPiece } from '../services/actions';

const defaultValues = () => ({
  id: uuid(),
  date_thrown: moment().format('YYYY-MM-DD'),
  is_hand_built: false,
  date_trimmed: null,
  date_to_bisque: null,
  returned_from_bisque: false,
  type: 'other',
  notes: '',
  clay_type: '',
  clay_weight: '0',
  date_to_glaze: null,
  returned_from_glaze: false,
  glaze: '',
  images: [],
  fate: '',
});

const NewPiece = ({ addPiece }) => {
  const navigate = useNavigate();

  const blah = (e) => {
    e.preventDefault();
    const piece = defaultValues();
    const { id } = piece;
    addPiece(piece);
    navigate(`/items/${id}`);
  };

  return (
    <Button variant="contained" color="primary" type="submit" onClick={blah}>
      Add New Piece
    </Button>
  );
};
const mstp = (s) => ({});
const mdtp = (d) => ({
  addPiece: addPiece(d),
});
export default connect(mstp, mdtp)(NewPiece);
