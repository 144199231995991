import React, { useState } from 'react';
import { connect } from 'react-redux';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import ImageAdd from './image-add';
import ImageAdmin from './image-admin';

const ImageAdminList = ({ images }) => {
  const [showHidden, setShowHidden] = useState(false);
  const toggleShowHidden = e => setShowHidden(e.target.checked);
  const [showFull, setShowFull] = useState(false);
  const toggleShowFull = e => setShowFull(e.target.checked);
  const imageList = images.filter(i => !i.hidden || showHidden)
    .filter(i => !i.isFullAssoc || showFull);

  return (<div>
    <ImageAdd />
    <div>
      <FormControlLabel
        control={
          <Switch checked={showHidden} onChange={toggleShowHidden} name="hidden" />
        }
        label="Show hidden images"
      />
      <FormControlLabel
        control={
          <Switch checked={showFull} onChange={toggleShowFull} name="full" />
        }
        label="Show images associated with all pieces in photo"
      />
    </div>
    {imageList.map(img => <ImageAdmin image={img} key={img.fileName} />)}
  </div>);
};

const mstp = s => ({
  images: s.images.images,
});
const mdtp = d => ({
});
export default connect(mstp, mdtp)(ImageAdminList);
