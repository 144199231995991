import * as ACTIONS from './action-types';
import findIndex from 'lodash/findIndex';
import { deepCopy } from './helpers';

/*
  image schema:
  - fileName <str>
  - tags [<str>,]
  - hidden: <bool>
  - numPieces: <int>
  - addedAt: <timestamp>
  - isFullAssoc: <bool> # whether image is associated with as many pieces as are in pieces (via numPieces)
  *** below virtual fields not saved to dropbox ***
  - src: <blob>
*/

const initialState = {
  doingWork: false,
  images: [],
  error: null,
};

function addImage(allImages, action) {
  // first check if image already exists
  const index = findIndex(allImages, { fileName: action.fileName });
  if (index > -1) {
    console.log('Image already exists');
    return allImages;
  }

  const image = {
    fileName: action.fileName,
    tags: [],
    src: action.url,
    numPieces: 1,
    addedAt: new Date().toISOString(),
    isFullAssoc: false,
  };
  return [...allImages, image];
}

function onInitialSet(image) {
  const img = {...image};
  if (!img.tags) img.tags = [];
  return img;
}

function onUpdateImage(images, fileName) {
  const nextState = deepCopy(images);
  const index = findIndex(nextState, { fileName });
  return { nextState, index };
}

function setImageSource(images, fileName, source) {
  return updateImageByName(images, fileName, { src: source });
  // const { nextState, index } = onUpdateImage(images, fileName);
  // if (index < 0) {
  //   console.log(`Did not find image in state; fileName ${fileName}`);
  //   return images;
  // }
  // nextState[index].src = source;
  // return nextState;
}

function updateImageByName(images, fileName, updates) {
  // updates param is an object of the things to change on the image

  const { nextState, index } = onUpdateImage(images, fileName);
  if (index < 0) {
    console.log(`Did not find image in state; fileName ${fileName}`);
    return images;
  }
  nextState[index] = {
    ...nextState[index],
    ...updates,
  };
  return nextState;
}

function editTags(images, fileName, tags) {
  return updateImageByName(images, fileName, { tags });
}

function setFullCounts(images, pieces) {
  const imgs = deepCopy(images);
  images.forEach(img => {
    if (img.tags.includes('inspiration') || img.tags.includes('insp-glaze')) {
      img.isFullAssoc = true;
      return;
    }

    // not an inspiration image, check the number of associated pieces
    // TODO: refactor this part to be more efficient 
    const numAssociated = pieces.reduce((count, piece) => {
      if (piece.images.includes(img.fileName)) count++;
      return count;
    }, 0);
    img.isFullAssoc = numAssociated >= img.numPieces;
  });
  return images;
}

export function imagesReducer(state = initialState, action = ({ type: null})) {
  switch (action.type) {
    case ACTIONS.IMAGE_ADD:
      return {
        ...state,
        doingWork: true,
        error: null,
      };
    case ACTIONS.IMAGE_ADD_SUCCESS:
      return {
        ...state,
        images: addImage(state.images, action),
        doingWork: false,
      }
    case ACTIONS.IMAGE_ADD_ERROR:
      return {
        ...state,
        doingWork: false,
        error: action.error,
      };
    case ACTIONS.IMAGE_GET_SUCCESS:
      return {
        ...state,
        images: setImageSource(state.images, action.fileName, action.data.result.link),
      };
    case ACTIONS.IMAGE_SET_TAGS:
      return {
        ...state,
        images: editTags(state.images, action.fileName, action.tags),
      };
    case ACTIONS.IMAGE_SET_PIECE_HIDDEN:
      return {
        ...state,
        images: updateImageByName(state.images, action.fileName, { hidden: action.hidden }),
      };
    case ACTIONS.IMAGE_SET_NUM_PIECES:
      return {
        ...state,
        images: updateImageByName(state.images, action.fileName, { numPieces: action.numPieces }),
      };
    case ACTIONS.IMAGES_SET_FULL:
      return {
        ...state,
        images: setFullCounts(state.images, action.pieces),
      };
    case ACTIONS.SYNC_FROM_DBX_SUCCESS:
      return {
        ...state,
        images: action.images.map(onInitialSet),
      };
    case ACTIONS.LOGOUT:
      return initialState;
    default:
      return state;
  }
}
