import React, { useState } from 'react';
import { connect } from 'react-redux';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ImageList from './image-list';

const Inspiration = ({ images }) => {
  const [selectedTags, setSelectedTags] = useState(['inspiration', 'insp-glaze']);
  const onTagSelect = (e, tags) => {
    if (tags.length === 0) setSelectedTags(['inspiration']);
    else setSelectedTags(tags);
  }
  const filteredList = images.filter(i => i.tags.some(t => selectedTags.includes(t)));

  return (<div>
    <div>
    <ToggleButtonGroup
        color="secondary"
        value={selectedTags}
        onChange={onTagSelect}
      >
        <ToggleButton value="inspiration">Inspiration</ToggleButton>
        <ToggleButton value="insp-glaze">Glaze Inspiration</ToggleButton>
      </ToggleButtonGroup>
    </div>
    <ImageList images={filteredList} />
  </div>);
};

const mstp = s => ({
  images: s.images.images,
});
const mdtp = d => ({
});
export default connect(mstp, mdtp)(Inspiration);
