import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import find from 'lodash/find';
import Piece from './piece';
import { setImagesFull, updatePiece } from '../../services/actions';

const PieceWrapper = ({ pieces, images, updatePiece, setImagesFull }) => {
  const { itemId } = useParams();
  const piece = find(pieces, { id: itemId });
  if (!piece) {
    console.log('piece not found...', itemId); // TODO: add a display here
    return <h1>Piece {itemId} not found!</h1>;
  }

  const onChange = (updated) => {
    updatePiece(itemId, updated);
  };

  const onToggleImgEditor = () => setImagesFull(pieces);

  return (
    <Piece
      id={itemId}
      piece={piece}
      allImages={images}
      onPieceChange={onChange}
      onToggleImgEditor={onToggleImgEditor}
    />
  );
};
const mstp = (s) => ({
  pieces: s.pieces.pieces,
  images: s.images.images,
});
const mdtp = (d) => ({
  setImagesFull: setImagesFull(d),
  updatePiece: updatePiece(d),
});
export default connect(mstp, mdtp)(PieceWrapper);
