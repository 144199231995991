import { useSelector } from 'react-redux'
import { Routes, Route } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import './index.css';
import DropboxUserLogin from './components/dropbox-user-auth';
import ImageAdminList from './components/image-admin-list';
import Inspiration from './components/inspiration';
import PiecesList from './components/pieces-list';
import Nav from './components/nav';
import Piece from './components/piece-mgmt';
import Tools from './components/tools';

function App() {
  const isLoggedIn = useSelector(state => state.account.isLoggedIn);
  const syncError = useSelector(state => state.account.syncError);

  if (!isLoggedIn) {
    return <DropboxUserLogin />
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <div className="App">
        <Nav />
        { syncError ? <Alert severity="error">Sync Error: {syncError}</Alert> : null }
        <div className="app-body">
          <Routes>
            <Route path="/" element={<PiecesList />} />
            <Route path="/images" element={<ImageAdminList />} />
            <Route path="/inspiration" element={<Inspiration />} />
            <Route path="/items/:itemId" element={<Piece />} />
            <Route path="/tools" element={<Tools />} />
          </Routes>
        </div>
      </div>
    </LocalizationProvider>
  );
}

export default App;
