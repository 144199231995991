import React from 'react';
import PotteryImage from './image-pottery';

const ImageList = ({ images }) => {
  // const imgEls = images.map(img => (<div>
  //   <PotteryImage image={img} key={img.fileName} />);
  // </div>);

  return (
    <div>
      {images.map((img) => {
        return (
          <div key={img.fileName}>
            <PotteryImage image={img} key={img.fileName} />
          </div>
        );
      })}
    </div>
  );
};

export default ImageList;
