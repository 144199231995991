import { Button } from '@mui/material';

const ClayButton = ({ clayType, selectedType, onClick }) => {
  const variant = clayType === selectedType ? 'contained' : 'outlined';
  return (
    <Button
      variant={variant}
      onClick={onClick}
      sx={{
        minWidth: 'auto',
        whiteSpace: 'nowrap',
        marginRight: '4px',
      }}
    >
      {clayType}
    </Button>
  );
};

export default ClayButton;
