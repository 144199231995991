import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button } from '@mui/material';
import { login } from '../services/actions';
import { getLoginCookie } from '../services/cookies';
import { authCallbackUrl, dbxClientId } from '../config';


const OneTimeHelper = ({ accessToken, cookieToken }) => {
  const dispatch = useDispatch();

  let doingLogin = false;
  const tryLogin = (token, fromCookie) => {
    doingLogin = true;
    login(dispatch)(token, fromCookie);
  }

  useEffect(() => {
    if (cookieToken) {
      console.log('Logging in from cookie...');
      tryLogin(cookieToken, true);
    }
  }, [cookieToken])

  useEffect(() => {
    if (accessToken) {
      tryLogin(accessToken);
    }
  }, [accessToken]);

  return doingLogin ? <h2>Hold please while we process your response...</h2> : null;
};



const DropboxUserLogin = () => {
  const [authUrl, setAuthUrl] = useState(null);
  useEffect(() => {
    if (!authUrl) {
      const dbx = new window.Dropbox.Dropbox({ clientId: dbxClientId });
      dbx.auth.getAuthenticationUrl(authCallbackUrl)
      .then(_authUrl => {
        setAuthUrl(_authUrl);
      })
      .catch(err => {
        console.log('Error generating auth url');
        console.log(err);
      });
    }
  }, [authUrl]);

  const cookieToken = getLoginCookie();
  const location = useLocation();
  let hash = location.hash;
  if (hash[0] === '#') hash = hash.substring(1);
  const params = new URLSearchParams(hash);
  const accessToken = params.get('access_token');

  return (
    <div className="pta-centered" style={{height: '90vh'}}>
      <OneTimeHelper accessToken={accessToken} cookieToken={cookieToken} />
      <a href={authUrl}>
        <Button type="submit" variant="contained" color="primary" disabled={!authUrl}>Log In With Dropbox</Button>
      </a>
    </div>
  );
};
export default DropboxUserLogin;
