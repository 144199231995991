import { applyMiddleware, createStore, combineReducers } from 'redux';
import {createLogger} from 'redux-logger';
import { loginReducer } from './login-reducer';
import { piecesReducer } from './pieces-reducer';
import { imagesReducer } from './images-reducer';
import { persistMiddleware } from './middleware';


const reducers = combineReducers({
  pieces: piecesReducer,
  account: loginReducer,
  images: imagesReducer,
});

const store = createStore(
  reducers,
  applyMiddleware(
    createLogger({
      collapsed: () => true
    }),
    persistMiddleware
  )
);

export default store;
