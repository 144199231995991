import * as actions from './action-types';
import { debounce } from './helpers';
import { _saveStoreToDbx } from './actions';

const debouncedSaveStore = debounce(_saveStoreToDbx, 12000);
const persistEventually = [
  actions.PIECE_ADD,
  actions.PIECE_UPDATE,
  actions.PIECE_ADD_IMAGE,
  actions.PIECE_REMOVE,
  actions.IMAGE_ADD_SUCCESS,
  actions.IMAGE_SET_TAGS,
  actions.IMAGE_SET_PIECE_HIDDEN,
];
const persistImmediately = [];
export const persistMiddleware = (store) => (next) => (action) => {
  const result = next(action);
  if (persistEventually.includes(action.type)) {
    debouncedSaveStore(store.dispatch);
  } else if (persistImmediately.includes(action.type)) {
    _saveStoreToDbx(store.dispatch);
  }
  return result;
};
