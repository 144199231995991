import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import PotteryImage from './image-pottery';
import {
  imageSetNumPieces,
  imageSetTags,
  imageTogglePieceHidden,
  setImagesFull,
} from '../services/actions';
import { getPiecesForImage } from '../services/helpers';


const ImageAdmin = ({ image, imageSetTags, imageTogglePieceHidden, setNumPieces, setImagesFull, allPieces }) => {
  const pieces = getPiecesForImage(image.fileName);
  const setFull = () => setImagesFull(allPieces);

  const togglePiecesSet = event => {
    imageTogglePieceHidden(image.fileName, event.target.checked);
  }

  const onTagSelect = (e, tags) => {
    imageSetTags(image.fileName, tags);
    setFull();
  }

  const numPiecesHandler = e => {
    const numPieces = e.target.value;
    if (numPieces < 0) setNumPieces(image.fileName, 0);
    else setNumPieces(image.fileName, numPieces);
    setFull();
  }

  return (<div>
    <div>
      <PotteryImage image={image} />
    </div>
    <div>
      <ToggleButtonGroup
        color="secondary"
        value={image.tags}
        onChange={onTagSelect}
      >
        <ToggleButton value="greenware">Greenware</ToggleButton>
        <ToggleButton value="bisque">Bisque</ToggleButton>
        <ToggleButton value="wetglaze">Wet Glaze</ToggleButton>
        <ToggleButton value="glaze">Glazed</ToggleButton>
        <ToggleButton value="inspiration">Inspiration</ToggleButton>
        <ToggleButton value="insp-glaze">Glaze Inspiration</ToggleButton>
      </ToggleButtonGroup>
    </div>
    <div className="imgAdminPieces">
      <p>Pieces associated with: {pieces.length}</p>
      <TextField
        id="numPieces"
        label="Pieces in image"
        value={image.numPieces}
        type="number"
        variant="filled"
        onChange={numPiecesHandler}
      />
      <p>Associated with all pieces: {image.isFullAssoc.toString()}</p>
      {
        pieces.map(p => <Link to={`/items/${p.id}`} key={p.id}><p>{p.id}</p></Link>)
      }
    </div>
    <div>
      <FormControlLabel
        control={
          <Switch checked={!!image.hidden} onChange={togglePiecesSet} name="hidden" />
        }
        label="Hide from piece editor"
      />
    </div>

    <hr />
  </div>);
};

const mstp = s => ({
  allPieces: s.pieces.pieces,
});
const mdtp = d => ({
  setNumPieces: imageSetNumPieces(d),
  imageSetTags: imageSetTags(d),
  imageTogglePieceHidden: imageTogglePieceHidden(d),
  setImagesFull: setImagesFull(d),
});
export default connect(mstp, mdtp)(ImageAdmin);
