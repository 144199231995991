import React, { useRef, useState } from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import ClayButton from './clay-button';
import DeletePiece from './delete';
import { imgNamesToDetails } from '../../services/helpers';
import PotteryImage from '../image-pottery';
import PieceImageAdd from './image-add';
import DNDImages from './image-list-reorder';
import style from './style.css';

const clayTypes = ['b-clay', 'reclaimed', '306', 'porcelin', 'white-stoneware'];
const glazes = [
  { number: 1, name: 'Shaner Clear' },
  { number: 2, name: 'Shaner White' },
  { number: 3, name: 'Antique White' },
  { number: 4, name: 'Matte White' },
  { number: 5, name: 'Yellow Salt' },
  { number: 6, name: 'Gustin Shino' },
  { number: 7, name: 'Lau Shino' },
  { number: 8, name: 'Blue Celedon' },
  { number: 9, name: 'Green Celedon' },
  { number: 10, name: 'Coleman Apple Green' },
  { number: 11, name: 'Josh Green' },
  { number: 12, name: 'Reitz Green' },
  { number: 13, name: 'Josh Blue' },
  { number: 14, name: 'Aviva Blue' },
  { number: 15, name: 'Lavender' },
  { number: 16, name: 'Rutile Blue' },
  { number: 17, name: 'Iron Red' },
  { number: 18, name: 'Temmoku' },
  { number: 19, name: 'Matte Black' },
  { number: 20, name: 'Chun Blue' },
  { number: 21, name: "Cohen's Red" },
  { number: 22, name: 'Amber Celedon' },
  { number: 23, name: "Tom's Purple" },
  { number: 24, name: 'Tea Dust' },
  { number: 25, name: 'Galaxy Black' },
];

const Piece = ({ piece, onPieceChange, allImages, onToggleImgEditor }) => {
  // Pass in all images (the allImages prop) from redux store
  // so that if we load a page and not all image URLs are loaded,
  // the image will load once the URL is looked up from dropbox
  const [editingImages, setEditImage] = useState(false);
  const glazeDescRef = useRef(null);
  const editImgText = editingImages ? 'Save Images' : 'Edit Images';
  const toggleEditImage = () => {
    onToggleImgEditor();
    setEditImage(!editingImages);
  };

  const imagesStatic = imgNamesToDetails(piece.images).map((img) => (
    <PotteryImage image={img} key={img.fileName} />
  ));

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    onPieceChange({
      ...piece,
      [name]: value,
    });
  };

  const setDate = (field) => (date) => {
    const formatted = date ? date.format('YYYY-MM-DD') : date; // can't format null
    onPieceChange({
      ...piece,
      [field]: formatted,
    });
  };

  const onCheck = (field) => (event) => {
    onPieceChange({
      ...piece,
      [field]: event.target.checked,
    });
  };

  const textBtn = (field, value) => (event) => {
    onPieceChange({
      ...piece,
      [field]: value,
    });
  };

  const glazeBtnClick = (glaze) => (event) => {
    let desc = piece.glaze;
    if (desc) desc += ' ';
    desc += `${glaze.name} (#${glaze.number})`;

    onPieceChange({
      ...piece,
      glaze: desc,
    });
    glazeDescRef.current.focus();
  };

  const onImageAdd = (fileName) => {
    onPieceChange({
      ...piece,
      images: [...piece.images, fileName],
    });
  };

  const onImageListChange = (imageList) => {
    onPieceChange({
      ...piece,
      images: imageList,
    });
  };

  const DatePickToolbar = ({ field }) => {
    return (
      <div>
        Clear Date:
        <Button onClick={() => setDate(field)(null)}>Clear</Button>
      </div>
    );
  };

  return (
    <form className="single-piece">
      <div>
        <FormLabel>Id:</FormLabel> {piece.id}
      </div>
      <div>
        <TextField
          id="notes"
          name="notes"
          label="notes"
          type="text"
          value={piece.notes}
          onChange={handleInputChange}
          multiline
          className="pta-textarea"
        />
      </div>
      <div>
        <DatePicker
          label="Date Thrown"
          value={piece.date_thrown}
          type="date"
          onChange={setDate('date_thrown')}
          renderInput={(params) => <TextField {...params} />}
          ToolbarComponent={() => <DatePickToolbar field="date_thrown" />}
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Switch
              checked={piece.is_hand_built}
              onChange={onCheck('is_hand_built')}
              name="handbuild"
            />
          }
          label="Is Hand Built"
        />
      </div>
      <div>
        <FormControl>
          <FormLabel>Type</FormLabel>
          <RadioGroup
            name="type"
            value={piece.type}
            onChange={handleInputChange}
            row
          >
            <FormControlLabel
              key="bowl"
              value="bowl"
              control={<Radio size="small" />}
              label="Bowl"
            />
            <FormControlLabel
              key="mug"
              value="mug"
              control={<Radio size="small" />}
              label="Mug/Cup"
            />
            <FormControlLabel
              key="vase"
              value="vase"
              control={<Radio size="small" />}
              label="Vase"
            />
            <FormControlLabel
              key="other"
              value="other"
              control={<Radio size="small" />}
              label="Other"
            />
          </RadioGroup>
        </FormControl>
      </div>
      <div className="form-spacer">
        <TextField
          id="clay_type"
          name="clay_type"
          label="Type of clay"
          type="text"
          value={piece.clay_type}
          onChange={handleInputChange}
        />
        <div>
          {clayTypes.map((type) => (
            <ClayButton
              id={type}
              key={type}
              clayType={type}
              selectedType={piece.clay_type}
              onClick={textBtn('clay_type', type)}
            />
          ))}
        </div>
      </div>
      <div>
        <TextField
          id="clay_weight"
          name="clay_weight"
          label="Weight of clay"
          type="number"
          value={piece.clay_weight}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <DatePicker
          label="Date Trimmed"
          value={piece.date_trimmed}
          type="date"
          clearable={true}
          onChange={setDate('date_trimmed')}
          renderInput={(params) => <TextField {...params} />}
          ToolbarComponent={() => <DatePickToolbar field="date_trimmed" />}
        />
      </div>
      <div className="form-spacer">
        <DatePicker
          label="To Bisque"
          value={piece.date_to_bisque}
          type="date"
          clearable={true}
          onChange={setDate('date_to_bisque')}
          renderInput={(params) => <TextField {...params} />}
          ToolbarComponent={() => <DatePickToolbar field="date_to_bisque" />}
        />
        <FormControlLabel
          label="Back from bisque"
          control={
            <Checkbox
              checked={piece.returned_from_bisque}
              onChange={onCheck('returned_from_bisque')}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
        />
      </div>
      <div>
        <TextField
          id="glaze"
          name="glaze"
          label="Glaze description"
          inputRef={glazeDescRef}
          type="text"
          value={piece.glaze}
          onChange={handleInputChange}
          multiline
          className="pta-textarea"
        />
      </div>
      <div className="glazeBtnWrapper">
        {glazes.map((g, i) => (
          <div key={i}>
            <Button className="glazeBtn" key={i} onClick={glazeBtnClick(g)}>
              {g.name} [{g.number}]
            </Button>
          </div>
        ))}
      </div>
      <div className="form-spacer">
        <DatePicker
          label="To Glaze"
          value={piece.date_to_glaze}
          type="date"
          clearable={true}
          onChange={setDate('date_to_glaze')}
          renderInput={(params) => <TextField {...params} />}
          ToolbarComponent={() => <DatePickToolbar field="date_to_glaze" />}
        />
        <FormControlLabel
          label="Back from glaze"
          control={
            <Checkbox
              checked={piece.returned_from_glaze}
              onChange={onCheck('returned_from_glaze')}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
        />
      </div>
      <div>
        <h2>Photos</h2>
        <Button onClick={toggleEditImage}>{editImgText}</Button>
        <div>
          {editingImages ? (
            <DNDImages images={piece.images} onListChange={onImageListChange} />
          ) : (
            imagesStatic
          )}
        </div>
        {editingImages ? (
          <PieceImageAdd
            pieceId={piece.id}
            allImages={allImages}
            selectedImages={piece.images}
            onSelect={onImageAdd}
          />
        ) : null}
        <Button onClick={toggleEditImage}>{editImgText}</Button>
      </div>
      <hr />
      <div>
        <FormControlLabel
          control={
            <Switch
              checked={piece.ruined}
              onChange={onCheck('ruined')}
              name="ruined"
            />
          }
          label="Mark as ruined"
        />
      </div>
      <div>
        <DeletePiece pieceId={piece.id} />
      </div>
      <div>
        <TextField
          id="fate"
          name="fate"
          label="Eventually used as:"
          type="text"
          value={piece.fate}
          onChange={handleInputChange}
          multiline
          className="pta-textarea"
        />
      </div>
    </form>
  );
};
export default Piece;
