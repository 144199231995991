import * as actions from './action-types';
import store from './redux-store';
import findIndex from 'lodash/findIndex';
import { blobToText, fileToUrl } from './helpers';
import { setLoginCookie } from './cookies';
import { dataFilePath } from '../config';

const imagesDir = '/images';
const fullPath = (fileName) => `${imagesDir}/${fileName}`;

const Dropbox = window.Dropbox.Dropbox;

function getDbx() {
  return store.getState().account.dbx;
}

export function setPieceFilter(dispatch) {
  return function (filter) {
    dispatch({
      type: actions.PIECE_LIST_SET_FILTER,
      filter,
    });
  };
}

export function addPiece(dispatch) {
  return function (piece) {
    dispatch({
      type: actions.PIECE_ADD,
      piece: piece,
    });
  };
}

export function updatePiece(dispatch) {
  return function (id, piece) {
    dispatch({
      type: actions.PIECE_UPDATE,
      piece: piece,
      id: id,
    });
  };
}

export function removePiece(pieceId) {
  return {
    type: actions.PIECE_REMOVE,
    pieceId: pieceId,
  };
}

export function addImage(dispatch) {
  return async function (fileInfo, fileUrl, toPiece = null) {
    const sourceName = fileInfo.name;
    dispatch({
      type: actions.IMAGE_ADD,
      fileName: sourceName,
      sourceName,
    });

    try {
      const resp = await getDbx().filesUpload({
        path: fullPath(sourceName),
        contents: fileInfo,
        autorename: true, // have dropbox rename if file exists
        mute: true,
      });
      const url = fileUrl || fileToUrl(fileInfo);
      const fileName = resp.result.name; // if rename occurred, this will be different from sourceName

      dispatch({
        type: actions.IMAGE_ADD_SUCCESS,
        file: fileInfo,
        url: url,
        fileName,
        sourceName,
      });
      if (toPiece) {
        dispatch({
          type: actions.PIECE_ADD_IMAGE,
          fileName,
          id: toPiece,
        });
      }
    } catch (err) {
      dispatch({
        type: actions.IMAGE_ADD_ERROR,
        error: err,
        fileName: sourceName,
      });
    }
  };
}

export function getImageUrl(dispatch) {
  return async function (fileName) {
    try {
      dispatch({
        type: actions.IMAGE_GET,
        fileName,
      });
      const dbx = getDbx();
      // const response = await dbx.filesDownload({
      const response = await dbx.filesGetTemporaryLink({
        path: fullPath(fileName),
      });
      dispatch({
        type: actions.IMAGE_GET_SUCCESS,
        fileName,
        data: response,
      });
    } catch (err) {
      dispatch({
        type: actions.IMAGE_GET_ERROR,
        error: err,
      });
    }
  };
}

export function setImagesFull(dispatch) {
  return function (pieces) {
    dispatch({
      type: actions.IMAGES_SET_FULL,
      pieces,
    });
  };
}

export function imageSetTags(dispatch) {
  return function (fileName, tags) {
    dispatch({
      type: actions.IMAGE_SET_TAGS,
      fileName,
      tags,
    });
  };
}

export function imageTogglePieceHidden(dispatch) {
  return function (fileName, hidden) {
    dispatch({
      type: actions.IMAGE_SET_PIECE_HIDDEN,
      fileName,
      hidden,
    });
  };
}

export function imageSetNumPieces(dispatch) {
  return function (fileName, numPieces) {
    dispatch({
      type: actions.IMAGE_SET_NUM_PIECES,
      fileName,
      numPieces,
    });
  };
}

export function login(dispatch) {
  return async function (token, fromCookie = false) {
    const dbx = new Dropbox({ accessToken: token });

    // try getting user account to verify that key is good
    try {
      const acct = await dbx.usersGetCurrentAccount();
      dispatch({
        type: actions.APPLY_DROPBOX,
        dropbox: dbx,
        account: acct.result,
      });
      if (!fromCookie) setLoginCookie(token);

      // TODO: need to remove hash from URL
      // this is necessary, otherwise we will log in again as soon as we try to log out

      loadData(dispatch)(dbx);
    } catch (err) {
      console.log('Error logging in');
      console.log(err);
    }
  };
}

export function logout(dispatch) {
  return function () {
    dispatch({
      type: actions.LOGOUT,
    });
  };
}

export function checkImageDirectory(dispatch) {
  return async function (cursor, dbx = getDbx()) {
    try {
      dispatch({ type: actions.IMAGE_CHECK_DIR });

      let func;
      if (cursor) {
        func = dbx.filesListFolderContinue({ cursor });
      } else {
        func = dbx.filesListFolder({
          path: imagesDir,
          recursive: false,
        });
      }

      const resp = await func;
      const images = store.getState().images.images;
      resp.result.entries.forEach((img) => {
        if (findIndex(images, { fileName: img.name }) === -1) {
          // image not in store, add image
          const fileName = img.name;
          dispatch({
            type: actions.IMAGE_ADD_SUCCESS,
            fileName,
          });
          getImageUrl(dispatch)(fileName);
        }
      });

      if (resp.result.has_more)
        return checkImageDirectory(dispatch)(resp.result.cursor, dbx);
    } catch (err) {
      dispatch({
        type: actions.IMAGE_CHECK_DIR_ERROR,
        error: err,
      });
    }
  };
}

export function loadData(dispatch) {
  return function (dbx) {
    dispatch({ type: actions.SYNC_FROM_DBX });
    if (!dbx) dbx = getDbx();

    dbx
      .filesDownload({ path: dataFilePath })
      .then((response) => {
        // console.log(response.result.fileBlob);
        // console.log(response.fileBinary);
        return blobToText(response.result.fileBlob);
      })
      .then((fileText) => {
        const data = JSON.parse(fileText);
        const { pieces = [], images = [] } = data;
        dispatch({
          type: actions.SYNC_FROM_DBX_SUCCESS,
          pieces,
          images,
        });
        // images.forEach((img) => {
        //   getImageUrl(dispatch)(img.fileName);
        // });
        return data;
      })
      .catch((err) => {
        dispatch({
          type: actions.SYNC_FROM_DBX_ERROR,
          error: err,
        });
      });
  };
}

export async function _saveStoreToDbx(dispatch) {
  try {
    dispatch({ type: actions.SYNC_TO_DBX });

    const dbx = getDbx();
    const pieces = store.getState().pieces.pieces;
    const imageSource = store.getState().images.images;
    const images = imageSource.map((img) => {
      const copy = { ...img };
      delete copy.src;
      return copy;
    });

    const toSync = JSON.stringify({
      pieces: pieces,
      images: images,
    });
    await dbx.filesUpload({
      path: dataFilePath,
      contents: toSync,
      mute: true,
      mode: 'overwrite',
    });
    dispatch({
      type: actions.SYNC_TO_DBX_SUCCESS,
    });
  } catch (err) {
    dispatch({
      type: actions.SYNC_TO_DBX_ERROR,
      error: err,
    });
  }
}

export function saveStoreToDbx(dispatch) {
  return function () {
    _saveStoreToDbx(dispatch);
  };
}
