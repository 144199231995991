import * as actions from './action-types';
import { clearLoginCookie } from './cookies';

const initialState = {
  isLoggedIn: false,
  dbx: null,
  account: null,
  syncing: false,
  syncError: false,
};

export function loginReducer(state = initialState, action = ({ type: null})) {
  switch (action.type) {
    case actions.APPLY_DROPBOX:
      return {
        isLoggedIn: true,
        dbx: action.dropbox,
        account: action.account,
      };
    case actions.SYNC_FROM_DBX:
    case actions.SYNC_TO_DBX:
      return { ...state,
        syncing: true,
        syncError: false,
      };
    case actions.SYNC_FROM_DBX_SUCCESS:
    case actions.SYNC_TO_DBX_SUCCESS:
      return { ...state,
        syncing: false,
      };
    case actions.SYNC_TO_DBX_ERROR:
    case actions.SYNC_FROM_DBX_ERROR:
      return { ...state,
        syncing: false,
        syncError: action.error || 'Error loading from dropbox',
      };
    case actions.LOGOUT:
      clearLoginCookie();
      return initialState;
    default:
      return state;
  }
}
