import Cookies from 'universal-cookie';

const cookies = new Cookies();
const tokenCookieName = 'DBXACCSTOKEN';

export function setLoginCookie(token) {
  // on oauth callback, get expires_in=14400; I assume that's 4 hours (60*60*4 = 14400)
  const maxAge = 60*60*4; // in seconds
  cookies.set(tokenCookieName, token, { maxAge });
}

export function getLoginCookie() {
  return cookies.get(tokenCookieName);
}

export function clearLoginCookie() {
  return cookies.remove(tokenCookieName);
}
