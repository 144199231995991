import React, { useState } from 'react';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { addImage } from '../services/actions';
import { fileToUrl } from '../services/helpers';

// TODO: setup drag and drop https://developer.mozilla.org/en-US/docs/Web/API/File/Using_files_from_web_applications#selecting_files_using_drag_and_drop

const ImageAdd = ({ addImage, pieceId, saving }) => {
  const [img, setImg] = useState(null);
  const [imgUrl, setImgUrl] = useState(null);

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length) {
      setImg(e.target.files[0]);
      const url = await fileToUrl(e.target.files[0]);
      setImgUrl(url);
    } else {
      setImg(null);
      setImgUrl(null);
    }
  };

  const onSubmit = (e) => {
    e.stopPropagation();
    e.preventDefault();
    addImage(img, imgUrl, pieceId);
    setImg(null);
    setImgUrl(null);
  };

  return (
    <div>
      <div>
        <div>
          {saving ? (
            <CircularProgress color="inherit" />
          ) : (
            <input type="file" onChange={onFileChange} accept="image/*" />
          )}
        </div>
        <div>
          <Button disabled={!img} onClick={onSubmit}>
            Add Image
          </Button>
        </div>
        <div>
          {imgUrl ? (
            <img
              src={imgUrl}
              style={{ maxWidth: 300 }}
              alt="thumbnail of uploaded file"
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

const mstp = (s) => ({
  saving: s.images.doingWork,
});
const mdtp = (d) => ({
  addImage: addImage(d),
});
export default connect(mstp, mdtp)(ImageAdd);
