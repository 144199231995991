import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';

import { logout, saveStoreToDbx } from '../services/actions';


const Nav = ({ account, logout, saveStoreToDbx, syncing }) => {
  return (
    <nav className="app-nav">
      <div>
        <Link to={'/'}>Home</Link>
        <Link to={'/images'}>Images</Link>
        <Link to={'/inspiration'}>Inspiration</Link>
        <Link to={'/tools'}>Tools</Link>
        <Button variant="outlined" color="secondary"
          onClick={saveStoreToDbx}
          disabled={syncing}>
          Force Save
          <CachedIcon className={syncing ? 'rotate' : ''} />
        </Button>
      </div>
      <div>
        <span>{account?.email}</span>
        <Button variant="outlined" onClick={logout}>Log Out</Button>
      </div>
    </nav>
  );
};
const mstp = s => ({
  account: s.account.account,
  syncing: s.account.syncing,
});
const mdtp = d => ({
  logout: logout(d),
  saveStoreToDbx: saveStoreToDbx(d),
});
export default connect(mstp, mdtp)(Nav);
