import { find } from 'lodash';
import store from './redux-store';

export function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

export function getPiecesForImage(fileName) {
  return store.getState().pieces.pieces.filter(p => p.images.includes(fileName));
}

export function imgNamesToDetails(imageNames = []) {
  const allImages = store.getState().images.images;
  return imageNames.map(fileName => {
    return find(allImages, { fileName }) || { fileName, src: '' };
  });
}

export function fileToUrl(file) {
  return new Promise((res, rej) => {
    const reader = new FileReader();
    reader.onload = e => res(e.target.result);
    reader.onerror = e => rej(e);
    reader.readAsDataURL(file);
  });
};

export function blobToText(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = e => resolve(e.target.result);
    reader.onerror = e => reject(e);
    reader.readAsText(blob);
  });
}
