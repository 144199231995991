import React from 'react';
import { connect } from 'react-redux';
import ReactJson from "react-json-view";

const DataViewer = ({ images, pieces }) => {
  const style = {
    padding: 16,
    borderRadius: 8,
    background: 'rgb(39, 40, 34)',
  };
  return (
    <div>
      <h1>Raw Data</h1>
      <h2>Pieces</h2>
      <ReactJson
        style={style}
        name={false}
        displayObjectSize={true}
        displayDataTypes={true}
        enableClipboard={false}
        src={pieces}
        theme="monokai"
      />

      <h2>Images</h2>
      <ReactJson
        style={style}
        name={false}
        displayObjectSize={true}
        displayDataTypes={true}
        enableClipboard={false}
        src={images}
        theme="monokai"
      />
    </div>
  );
};

const mstp = s => ({
  pieces: s.pieces.pieces,
  images: s.images.images,
});
const mdtp = d => ({});
export default connect(mstp, mdtp)(DataViewer);
