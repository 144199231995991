export const APPLY_DROPBOX = 'APPLY_DROPBOX';
export const LOGOUT = 'LOGOUT';

export const PIECE_ADD = 'PIECE_ADD';
export const PIECE_UPDATE = 'PIECE_UPDATE';
export const PIECE_ADD_IMAGE = 'PIECE_ADD_IMAGE';
export const PIECE_LIST_SET_FILTER = 'PIECE_LIST_SET_FILTER';
export const PIECE_REMOVE = 'PIECE_REMOVE';

export const IMAGE_ADD = 'IMAGE_ADD';
export const IMAGE_ADD_SUCCESS = 'IMAGE_ADD_SUCCESS';
export const IMAGE_ADD_ERROR = 'IMAGE_ADD_ERROR';
export const IMAGE_GET = 'IMAGE_GET_URL';
export const IMAGE_GET_SUCCESS = 'IMAGE_GET_URL_SUCCESS';
export const IMAGE_GET_ERROR = 'IMAGE_GET_URL_ERROR';
export const IMAGE_CHECK_DIR = 'IMAGE_CHECK_DIR';
// export const IMAGE_CHECK_DIR_SUCCESS = 'IMAGE_CHECK_DIR_SUCCESS';
export const IMAGE_CHECK_DIR_ERROR = 'IMAGE_CHECK_DIR_ERROR';
export const IMAGE_SET_TAGS = 'IMAGE_SET_TAGS';
export const IMAGE_SET_PIECE_HIDDEN = 'IMAGE_SET_PIECE_HIDDEN';
export const IMAGE_SET_NUM_PIECES = 'IMAGE_SET_NUM_PIECES';
export const IMAGES_SET_FULL = 'IMAGES_SET_FULL';

export const SYNC_TO_DBX = 'SAVE_TO_DROPBOX';
export const SYNC_TO_DBX_SUCCESS = 'SAVE_TO_DROPBOX_SUCCESS';
export const SYNC_TO_DBX_ERROR = 'SAVE_TO_DROPBOX_ERROR';
export const SYNC_FROM_DBX = 'LOAD_FROM_DROPBOX';
export const SYNC_FROM_DBX_SUCCESS = 'LOAD_FROM_DROPBOX_SUCCESS';
export const SYNC_FROM_DBX_ERROR = 'LOAD_FROM_DROPBOX_ERROR';
